import {getCookie} from './cookieJar'

const cookie = () => JSON.parse(getCookie('ep_ref_data'))

export const parseUtmParams = query => {
  const {ep_ref, utm_campaign, utm_content, utm_medium, utm_source, utm_term} = query
  const ep_meta = cookie() ? cookie().ep_meta : {}

  return {
    ep_ref_data: JSON.stringify((ep_ref ? {ep_ref} : null) || cookie() || {ep_ref: ''}),
    campaign: utm_campaign || ep_meta.utm_campaign,
    content: utm_content || ep_meta.utm_content,
    medium: utm_medium || ep_meta.utm_medium,
    source: utm_source || ep_meta.utm_source,
    term: utm_term || ep_meta.utm_term
  }
}
