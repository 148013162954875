import assign from 'object-assign'

import ActionPrototype from '../../../shared_components/ActionPrototype'
import AppDispatcher from '../../../lib/ep-dispatcher'
import KeyMirror from 'keymirror'
import SessionApi from '../web/SessionApi'

const SignUpActions = assign({}, ActionPrototype, {
  Types: KeyMirror({
    DID_SIGN_UP: null,
    DID_CONFIRM_USER: null,
    DID_FETCH_USER_CONFIRMATION: null,
    DID_RESEND_CONFIRMATION_EMAIL: null,
    DID_GET_PARTNER_NAME: null
  }),

  signUp(data) {
    this.fireApi('post', '/users', data, {successAction: this.Types.DID_SIGN_UP})
  },

  confirmUser(data, processConfirmationSuccess) {
    this.fireApi('put',
      `users/confirmation/${data.confirmationToken}`,
      data,
      {
        JSONHead: 'user',
        onSuccess: processConfirmationSuccess,
        successAction: this.Types.DID_CONFIRM_USER
      }
    )
  },

  fetchUserConfirmation(userId) { // refactor bindToStores to use actions name preceded by other name apart from fetch
    this.fireApi('get',
      `users/confirmation/${userId}`,
      null,
      {
        JSONHead: 'user',
        onSuccess: SessionApi.setTokenWithActionCallbackData,
        successAction: this.Types.DID_FETCH_USER_CONFIRMATION
      }
    )
  },

  resendConfirmationEmail(data) {
    this.fireApi('put', '/users/confirmation_email', data, {successAction: this.Types.DID_RESEND_CONFIRMATION_EMAIL})
  },

  getPartnerName(discountCode) {
    if (discountCode) {
      // Go fetch the partner if we have a discount code:
      this.fireApi(
        'get',
        `partners/show_name/${discountCode}`,
        null,
        {
          JSONHead: 'partner',
          successAction: this.Types.DID_GET_PARTNER_NAME
        }
      )
    } else {
      // Fall back to the Everplans Consumer partner if we have no discount code:
      AppDispatcher.dispatch({
        actionType: this.Types.DID_GET_PARTNER_NAME,
        data: {name: 'Everplans Consumer'}
      })
    }
  }
})

export default SignUpActions
